import logo from './logo.svg';
import './App.css';
import {useState} from 'react'
import AdSense from 'react-adsense';

function App() {
    const [input, setInput] = useState();

    function download(url) {

    }
    return (
        <div className="App">
            <header className="App-header">

                <AdSense.Google
                    client='ca-pub-6705616556499368'
                    slot='3661310811'
                    style={{ width: 360, height: 90, float: 'left' }}
                    format=''
                />
                <h1 style={{fontSize : 18}}><span style={{color: 'red'}}>Pinterest</span> Video Downloader</h1>
                <input type={'search'} onChange={(data) => {
                    setInput(data.target.value)
                }} value={input} style={{
                    fontSize: 15,
                    width: 320, borderRadius: 10, borderWidth: 1, borderColor: 'blue', height: 50, marginLeft: 16,
                }} placeholder={'Enter Pinterest\'s Link here'}></input>

                <div style={{marginTop: 16}}>
                    <button onClick={() => {
                        navigator.clipboard.readText()
                            .then(text => {
                                setInput(text)
                            })
                            .catch(err => {
                                console.error('Failed to read clipboard contents: ', err);
                            });
                    }} style={{borderRadius: 5, height: 40}}>Paste
                    </button>
                    <button style={{borderRadius: 5, height: 40}}>Download</button>
                </div>


                <div style={{textAlign: 'start'}}>
                    <h2>Download Video From Pinterest Free. All Devices</h2>
                    <div>DotSave is best download Video Pinterest and Photo Pinterest website/tool.</div>
                    <div>It works for computers, tablets and mobile devices.</div>
                    <div>Download Pinterest Video Full HD, 2K, 4K</div>

                    <h2>Feature</h2>
                    <div>Download Pinterest Video with FullHD, 720p, 320p ...</div>
                    <div>Download Pinterest Photo with Original Size, Large, Medium, Thumbnail size ...</div>
                    <div>Download Pinterest Video without installing anythings</div>
                    <h2>How to Download</h2>
                    <div>Step 1 : Copy the Pinterest Video's Link</div>
                    <div>Step 2 : Click Download and Wait few seconds</div>
                    <div>Step 3 : Choose Download and Save Video</div>
                </div>
            </header>
        </div>
    );
}

export default App;
